import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { getDonationList } from "../../../redux/donation_slice";
import { useDispatch, useSelector } from "react-redux";
import {
  formatCapitalizeFirstLetter,
  formatDate,
  exportCSV,
} from "../../components/helper";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import addIcon from "../../../../assets/old_ravian_assests/add.png";
import { format } from "date-fns";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import Loader from "../../components/loader";
import Paginator from "../../components/paginator";
import useDebounce from "../../components/hooks/useDebounce";

export default function DonationScreen() {
  const donationReducer = useSelector((state) => state.donation || {});
  const { donationList, loading, totalRecords } = donationReducer;

  const [filterdList, setFilteredList] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [filterSelectedStatus, setFilterSelectedStatus] = useState(null);
  const [dateRange, setDateRange] = useState([
    new Date("2024-01-01"),
    new Date("2024-12-30"),
  ]);

  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const debouncedGlobalFilter = useDebounce(globalFilter, 500);

  const dispatch = useDispatch();

  const fromDate =
    dateRange?.[0] && format(new Date(dateRange?.[0]), "yyyy-MM-dd");
  const toDate =
    dateRange?.[0] && format(new Date(dateRange?.[1]), "yyyy-MM-dd");

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      dispatch(
        getDonationList({
          fromDate: fromDate,
          toDate: toDate,
          id: filterSelectedStatus,
          search: debouncedGlobalFilter || null,
          page: page,
          recordsPerPage: rows,
        })
      );
    }
  }, [
    dispatch,
    filterSelectedStatus,
    dateRange,
    page,
    rows,
    debouncedGlobalFilter,
  ]);

  useEffect(() => {
    if (donationList) {
      setFilteredList(donationList?.data);
    }
  }, [donationList]);

  const handleGlobalFilterChange = (e) => {
    let searchValue = e.target.value.toLowerCase();
    const isPhoneNumberSearch = /^\+\d+/.test(searchValue);
    if (isPhoneNumberSearch) {
      searchValue = searchValue.replace(/^\+/, "");
    }
    setGlobalFilter(searchValue);
  };

  const filterStatusValue = [
    {
      label: "Library Donations",
      value: "a3c9399a-39bf-4fc7-8883-9eafdc4e827c",
    },
    { label: "Fee Collection", value: "ce81dc58-4d19-42e4-bde9-660b7d1c7fb5" },
    { label: "Eid Donations", value: "b4e2f365-a2b4-4929-b9b0-a255c027c18cs" },
    {
      label: "Green Initiatives",
      value: "e177fe01-feed-42af-b446-5b7b8edb1a36",
    },
    { label: "Planting Tree", value: "47cac553-73c3-4669-af11-e52215047f70" },
    {
      label: "Christmas Donations",
      value: "e95c56e2-9000-4bb7-8015-fa43efd8e237",
    },
    { label: "Others", value: "8c27dcf1-12e6-4686-98c9-338d5c0d1651" },
  ];

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    setRows(event.rows);
  };


  const exportCSV = () => {
    dt.current.exportCSV();
  }
  
  const dt = useRef(null);

  const serialNumberTemplate = (rowData, column) => {
    return column.rowIndex + 1 + first;
  };

  const columnsData = [
    {
      header: "Sr. No",
      body: serialNumberTemplate,
    },
    {
      field: "name",
      header: "Name",
      body: (rowData) => formatCapitalizeFirstLetter(rowData, "name"),
    },
    {
      field: "phone",
      header: "Mobile No.",
    },
    {
      field: "purpose",
      header: "Purpose",
      body: (rowData) => formatCapitalizeFirstLetter(rowData, "purpose"),
    },
    {
      field: "createdDate",
      header: "Date",
      body: (rowData) => formatDate(rowData.createdDate),
    },
    {
      field: "amount",
      header: "Amount",
      body: (rowData) => `PKR ${rowData.amount}`,
    },
  ];

  return (
    <>
      <div className="grid  align-items-center mt-2 mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10  justify-content-end filter-responsive">
          <Dropdown
            className="status__dropdown dropdown-size mt-3"
            value={filterSelectedStatus}
            onChange={(e) =>
              setFilterSelectedStatus(e.value !== undefined ? e.value : null)
            }
           
            optionValue="value"
            options={filterStatusValue}
            optionLabel="label"
            placeholder="Select Status"
            showClear
          />
          <Calendar
            className="calender-size"
            showIcon
            placeholder="Select Date Range"
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
          />
          {/* <Button
            label='Add Donation Type'
            icon='pi pi-plus'
            onClick={() => setShowAddDialog(true)}
            className='p-button ml-3'
          /> */}
          <Button
            className="p-button export_button"
            icon="pi pi-upload"
            label="Export to Excel"
            onClick={exportCSV}
          />
        </div>
      </div>

      <div className="card card-donation">
        {loading && <Loader />}
        <DataTable
          scrollable
          scrollHeight="500px"
          ref={dt}
          loading={loading}
          emptyMessage="No record found."
          value={filterdList}
          className="custom-data-table"
        >
          {columnsData?.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
}
