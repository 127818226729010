import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { createGallery } from "../../../redux/gallery_images_slice/gallery_slice";
import { getCategoryList } from "../../../redux/gallery_images_slice/category_slice";
import { toast } from "react-toastify";
import ActionButtons from "../../components/action_buttons";
import { InputTextarea } from "primereact/inputtextarea";

const currentYear = new Date().getFullYear();
const yearOptions = Array.from({ length: 10 }, (_, i) => ({
  label: (currentYear - i).toString(),
  value: (currentYear - i).toString(),
}));

const AddGalleryImages = ({ onHide }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const loginUser = useSelector((state) => state.loginUser);
  const { user } = loginUser;

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const imagesArray = Array.from(files);

    const newImagesBase64 = [];

    imagesArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        newImagesBase64.push(base64String);

        if (newImagesBase64.length === imagesArray.length) {
          formik.setFieldValue("gallery", [
            ...formik.values.gallery,
            ...newImagesBase64,
          ]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    year: Yup.string().nullable().required("Year is required"),
    category: Yup.string().required("Category is required"),
    gallery: Yup.string()
      .required("Image is required")
      .transform((value, originalValue) => {
        return Array.isArray(originalValue) ? originalValue[0] : originalValue;
      }),
  });

  const formik = useFormik({
    initialValues: {
      createdBy: user?.data?.id,
      year: null,
      category: "",
      title: "",
      description: "",
      type: "image",
      gallery: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleGallery(values);
    },
  });

  const handleGallery = (values) => {
    setLoading(true);
    const payload = {
      ...values,
    };
    dispatch(createGallery(payload))
      .then(() => {
        setLoading(false);
        toast.success("Gallery images Added successfully");
        onHide();
        // dispatch(getGalleryList({}));
      })
      .catch((error) => {
        toast.error("Failed to add gallery images");
        setLoading(false);
      });
  };

  const typeOptions = [
    { label: "Image", value: "image" },
    { label: "Video", value: "video" },
  ];

  const categoryReducer = useSelector((state) => state.category || {});
  const categoryList = categoryReducer.categoryList;

  let categories = [];
  if (formik.values.year) {
    const categoryListData =
      categoryList && categoryList.data ? categoryList.data : [];
    const activeCategories = categoryListData.filter(
      (item) => item.deleted === 0
    );
    categories = activeCategories.map((item) => item.category);
  }

  useEffect(() => {
    if (formik.values.year) {
      dispatch(getCategoryList({ year: formik.values.year }));
    }
  }, [dispatch, formik.values.year]);

  const handleRemoveImage = (indexToRemove) => {
    const updatedGallery = formik.values.gallery.filter(
      (_, index) => index !== indexToRemove
    );
    formik.setFieldValue("gallery", updatedGallery);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="p-fluid formgrid grid ">
        <div className="field md:col-6 responsive-col">
          <label htmlFor="">
            {" "}
            Title<span className="asteric">&nbsp;*</span>
          </label>
          <InputText
            id="title"
            value={formik.values.title || ""}
            onChange={formik.handleChange}
          />
          {formik.errors.title && formik.touched.title && (
            <small className="error">{formik.errors.title}</small>
          )}
        </div>
        <div className="field md:col-6 responsive-col"></div>
        <div className="field md:col-4 responsive-col">
          <label htmlFor="">
            Year<span className="asteric">&nbsp;*</span>
          </label>
          <Dropdown
            id="year"
            value={formik.values.year}
            options={yearOptions}
            onChange={(e) => {
              formik.setFieldValue("year", e.value);
            }}
            placeholder="Select Year"
            className="status__dropdown m-0"
          />
          {formik.touched.year && formik.errors.year && (
            <div className="error">{formik.errors.year}</div>
          )}
        </div>
        <div className="field md:col-4 responsive-col">
          <label htmlFor="">
            Category<span className="asteric">&nbsp;*</span>
          </label>
          <Dropdown
            id="category"
            value={formik.values.category}
            options={categories}
            onChange={(e) => {
              formik.setFieldValue("category", e.value);
            }}
            placeholder="Choose Category"
            className="status__dropdown m-0"
          />
          {formik.touched.category && formik.errors.category && (
            <div className="error">{formik.errors.category}</div>
          )}
        </div>
        <div className="field md:col-4 responsive-col">
          <label htmlFor="">
            {" "}
            Type<span className="asteric">&nbsp;*</span>
          </label>
          <Dropdown
            id="type"
            value={formik.values.type}
            options={typeOptions}
            onChange={(e) => {
              formik.setFieldValue("type", e.value);
            }}
            placeholder="Select type"
            className="status__dropdown  m-0 "
          />
        </div>

        <div className="field md:col-12 responsive-col">
          <label htmlFor="">
            Description<span className="asteric">&nbsp;*</span>
          </label>
          <InputTextarea
            rows={5}
            id="description"
            value={formik.values.description || ""}
            onChange={formik.handleChange}
          />
          {formik.errors.description && formik.touched.description && (
            <small className="error">{formik.errors.description}</small>
          )}
        </div>

        <div className="field md:col-12 responsive-col">
          <div>
            <label htmlFor="">
              Upload Images<span className="asteric ">&nbsp;*</span>
            </label>
            <br />
            <input
              type="file"
              onChange={(event) => handleFileUpload(event)}
              accept=".png, .jpg, .jpeg, .svg"
              multiple
              className="d-none my-3"
            />
          </div>

          <div className="block mb-2 upload_images multiple-images">
            <input
              type="file"
              onChange={(event) => handleFileUpload(event)}
              accept=".png, .jpg, .jpeg, .svg"
              multiple
            />
            <div className="upload-grid upload-multiple">
              {formik.values.gallery.map((image, index) => (
                <div key={index} className="image-preview">
                  <img src={image} alt={`Image ${index}`} />
                  <Button
                    icon="pi pi-times"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveImage(index);
                    }}
                  />
                </div>
              ))}
              {formik.values.gallery.length % 3 !== 0 && (
                <div className="filler"></div>
              )}
            </div>
          </div>

          {formik.errors.gallery && formik.touched.gallery && (
            <small className="error">{formik.errors.gallery}</small>
          )}
        </div>
      </div>
      <br />
      <br />
      <br />
      <ActionButtons
        showSave
        onCancel={onHide}
        onSave={formik.handleSubmit}
        loading={loading}
        saveLabel="Add"
        cancelLabel="Close"
      />
    </form>
  );
};

export default AddGalleryImages;
