import { configureStore } from "@reduxjs/toolkit";
import login_user_slice from "./redux/auth_slice/login_user_slice";
import help_slice from "./redux/help_slice";
import membership_slice from "./redux/membership_slice";
import banner_slice from "./redux/banner_slice";
import donation_slice from "./redux/donation_slice";
import announcement_slice from "./redux/news_slice";
import event_slice from "./redux/event_slice";
import dashbaord_slice from "./redux/dashbaord_slice";
import category_slice from "./redux/gallery_images_slice/category_slice";
import gallery_slice from "./redux/gallery_images_slice/gallery_slice";
import user_slice from "./redux/auth_slice/user_slice";
import role_slice from "./redux/auth_slice/role_slice";

const initialState = {};

const store = configureStore({
  reducer: {
    loginUser: login_user_slice,
    user: user_slice,
    help: help_slice,
    membership: membership_slice,
    banner: banner_slice,
    donation: donation_slice,
    announcement: announcement_slice,
    event: event_slice,
    dashboard: dashbaord_slice,
    category: category_slice,
    gallery: gallery_slice,
    role: role_slice
  },
  preloadedState: initialState,
});

export default store;
