import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { getMembershipList } from "../../../redux/membership_slice";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../assets/old_ravian_assests/edit_icon.png";
import editIconRed from "../../../../assets/old_ravian_assests/edit_icon_red.png";
import profileImage from "../../../../assets/old_ravian_assests/profile.png";
import {
  formatDate,
  formatCapitalizeFirstLetter,
  statusColors,
  exportCSV,
} from "../../components/helper";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import config from "../../../../config";
import EditMembershipStatus from "./edit_membership_status";
import { confirmDialog } from "primereact/confirmdialog";
import Loader from "../../components/loader";
import Paginator from "../../components/paginator";
import useDebounce from "../../components/hooks/useDebounce";
import { format } from "date-fns";
import CustomImagePreview from "../../components/custom_imagepreview";

export default function MemberShipScreen() {
  const membershipReducer = useSelector((state) => state.membership || {});
  const { membershipList, loading, totalRecords } = membershipReducer;

  const [filterdList, setFilteredList] = useState();
  const [idValue, setIdValue] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [filterSelectedStatus, setFilterSelectedStatus] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [dateRange, setDateRange] = useState([
    new Date("2024-01-01"),
    new Date("2024-12-30"),
  ]);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const debouncedGlobalFilter = useDebounce(globalFilter, 500);

  const dispatch = useDispatch();

  const fromDate =
    dateRange?.[0] && format(new Date(dateRange?.[0]), "yyyy-MM-dd");
  const toDate =
    dateRange?.[0] && format(new Date(dateRange?.[1]), "yyyy-MM-dd");

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      dispatch(
        getMembershipList({
          fromDate: fromDate,
          toDate: toDate,
          status: filterSelectedStatus,
          type: null,
          search: debouncedGlobalFilter || null,
          page: page,
          recordsPerPage: rows,
        })
      );
    }
  }, [
    dispatch,
    dateRange,
    filterSelectedStatus,
    page,
    rows,
    debouncedGlobalFilter,
  ]);

  useEffect(() => {
    if (membershipList) {
      setFilteredList(membershipList?.data);
    }
  }, [membershipList]);

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    setRows(event.rows);
  };

  const filterStatusValue = [
    { label: "Pending", value: "pending" },
    { label: "Expired", value: "expired" },
    { label: "Rejected", value: "rejected" },
    { label: "Active", value: "active" },
  ];

  const onHide = () => {
    setShowAddUserDialog(false);
  };

  const actionTemplate = (rowData) => {
    const handleEditClick = () => {
      setIdValue(rowData.id);
      setStatusValue(rowData.status);
      setShowAddUserDialog(true);
    };

    const handleMembershipPending = () => {
      confirmDialog({
        header: "Membership Payment Pending",
        message: "Membership Payment Pending",
        icon: "pi pi-info-circle",
        acceptLabel: "OK",
        className: "confirm-dialog-custom",
        rejectClassName: "confirm-dialog-reject-btn",
        accept: () => {},
      });
    };

    if (rowData.status === "pending") {
      if (rowData.paid === 1) {
        return (
          <Button
            className="p-button-rounded p-button-edit p-0"
            onClick={handleEditClick}
            aria-label="Edit"
          >
            <img src={editIcon} alt="Edit" />
          </Button>
        );
      } else {
        return (
          <Button
            className="p-button-rounded p-button-edit p-0"
            onClick={handleMembershipPending}
            aria-label="Edit"
          >
            <img src={editIcon} alt="Edit" />
          </Button>
        );
      }
    } else if (rowData.status === "active") {
      return <img src={editIcon} alt="Edit" className="edit-icon-disabled" />;
    } else if (rowData.status === "rejected") {
      return <img src={editIcon} alt="Edit" className="edit-icon-disabled" />;
    }
    return null;
  };

  const exportCSVFile = () => {
    dispatch(
      getMembershipList({
        fromDate: "2024-02-23",
        toDate: "2025-02-23",
        status: filterSelectedStatus,
      })
    ).then(() => {
      if (membershipList && membershipList.data) {
        const csvData = membershipList.data.map((item) => ({
          ID: item.memberId,
          Name: formatCapitalizeFirstLetter(item, "name"),
          "Applied Date": formatDate(item.createdDate),
          Status: formatCapitalizeFirstLetter(item, "status"),
        }));
        exportCSV(csvData, "membership_data.csv");
      }
    });
  };

  const dt = useRef(null);


  const imageTemplate = (rowData) => {
    const imageUrl = rowData?.document
      ? config.baseUrl + rowData.document
      : profileImage;
    return (
      <div>
        {/* <img
          src={imageUrl}
          className="datatable-profile-image"
          alt="Profile"
          onError={(e) => (e.target.src = profileImage)}
        /> */}
          <CustomImagePreview   src={imageUrl} />

      </div>
    );
  };

  const columnsData = [
    {
      field: "memberId",
      header: "ID",
    },
    {
      field: "name",
      header: "Name",
      body: (rowData) => formatCapitalizeFirstLetter(rowData, "name"),
    },
    {
      field: "status",
      header: "Status",
      body: statusColors,
    },
    {
      field: "createdDate",
      header: "Applied Date",
      body: (rowData) => formatDate(rowData.createdDate),
    },
    {
      header: "Image",
      body: imageTemplate,
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-image">
              <img src={editIconRed} alt="Edit Icon" />
            </div>
            <div className="popup-header-text">Edit</div>
          </div>
        }
        visible={showAddUserDialog}
        onHide={() => {
          onHide("showAddDialog");
        }}
        className="responsive-dialog"
      >
        <EditMembershipStatus
          id={idValue}
          initialStatus={statusValue}
          onHide={() => onHide("showAddDialog")}
        />
      </Dialog>
      <div className="grid  align-items-center">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10  justify-content-end filter-responsive">
          <Dropdown
            className="status__dropdown dropdown-size mt-3"
            value={filterSelectedStatus}
            onChange={(e) =>
              setFilterSelectedStatus(e.value !== undefined ? e.value : null)
            }
            optionValue="value"
            options={filterStatusValue}
            optionLabel="label"
            placeholder="Select Status"
            showClear
          />

          <Calendar
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            readOnlyInput
            showIcon
            className="calender-icon"
          />

          <Button
            className="p-button export_button"
            icon="pi pi-upload"
            label="Export to Excel"
            onClick={exportCSVFile}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          scrollable
          scrollHeight="500px"
          ref={dt}
          emptyMessage="No record found."
          value={filterdList}
          className="custom-data-table"
        >
          {columnsData?.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
}
